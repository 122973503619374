import logo from './logo.svg';
import './App.css';

import { useState } from 'react';
import Header from './components/Header';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Carousel from 'react-bootstrap/Carousel'
import Col from 'react-bootstrap/Col';
import { Stack } from 'react-bootstrap';


function App() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };


  return (
    <>
      <Header />
      <Container className="app-body">
        <Row>
          <h2>Upcoming happenings</h2>
        </Row>
        <Row>
          <Carousel className='app-carrot' slide={false} activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item interval={60000} className='justify-content-center'>
              <a href="https://downloadfestival.co.uk/">
                <img
                  className="d-block w-80"
                  src="https://downloadfestival.co.uk/assets/images/2023/dl-logo-combined.svg"
                  alt="First slide"
                />
              </a>
              <Carousel.Caption>
                <h3>
                  8-11 JUN 2023
                </h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={60000}>
              <a href="https://www.metallica.com/tour/2024-06-07-helsinki-finland.html">
                <img
                  className="d-block w-80"
                  src="https://www.metallica.com/on/demandware.static/-/Sites-Metallica-Library/default/dw8b35e89d/images/blog/news/2022-11-28-m72-world-tour.png"
                  alt="Second slide"
                />
              </a>
              <Carousel.Caption>
                <h3>
                  07-09 JUN 2024
                </h3>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Row>
      </Container>
    </>
  );
}

export default App;
