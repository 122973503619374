import logo from '../logo.svg';

import {
  Container, Row, Col, Form, Button, Navbar, Nav,
  NavbarBrand, NavLink, NavItem
} from 'react-bootstrap';


const Header = () => (
  <header className="app-header">
    <Row>
      <h1>Welcome to T34M N0SL33P</h1>
    </Row>
  </header>
);

export default Header;